import { Container, Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next';
// import './NeedHelp.scss'
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneIcon from '@material-ui/icons/Phone';

export default function NeedHelp() {
    const { t } = useTranslation();
    return (
        <div 
        data-sal="slide-right"
        data-sal-duration="2000"
        className='need-help'>
            <Container>
                <Grid container alignItems='center' justify='flex-start' spacing={4}>
                    <Grid item className='item-title item-titlehelp'>
                        <h2 className='title-needhelp'>{t('Reserve Need Help')}</h2>
                        <div className='need-helpf'>
                            <h3 className='nh-title'>{t('Reserve Need Help Message')}</h3>
                            <div className='nh-flex'>
                                <MailOutlineIcon />
                                <p className='n-hf'>waitnrestcontact@gmail.com</p>
                            </div>
                            <div className='nh-flex'>
                                <PhoneIcon />
                                <p className='n-hf'>+57 3043207917</p>
                            </div>
                        </div>
                        <div className='need-helpf'>
                            {t('Reserve Need Help 1')}
                        </div>
                        <div className='need-helpf nhf2'>
                            {t('Reserve Need Help 2')}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
