import React from 'react'
import { Container, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next';
import IconProtocol1 from '../../../images/icon-protocol-1.png';
import IconProtocol2 from '../../../images/icon-protocol-2.png';
import IconProtocol3 from '../../../images/icon-protocol-3.png';
import IconProtocol4 from '../../../images/icon-protocol-4.png';
import './Protocols.scss'

export default function Protocols() {
    const { t } = useTranslation();

    return (
        <div
            className='protocols'>
            <Container
                data-sal="slide-left"
                data-sal-duration="2000">
                <Grid container>
                    <Grid item xs={12}>
                        <h2>{t('Reserve Protocols')}</h2>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <div>
                            <p><span><img src={IconProtocol1} alt='protocolo 1' /></span>{t('Reserve Protocols P1')}</p>
                        </div>
                        <div>
                            <p><span><img src={IconProtocol2} alt='protocolo 2' /></span>{t('Reserve Protocols P2')}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <div>
                            <p><span><img src={IconProtocol3} alt='protocolo 3' /></span>{t('Reserve Protocols P3')}</p>
                        </div>
                        <div>
                            <p><span><img src={IconProtocol4} alt='protocolo 4' /></span>{t('Reserve Protocols P4')}</p>
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
