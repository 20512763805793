import React, { useState } from 'react'
import { Fab, Box } from '@material-ui/core'
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import Map from '../../images/map.png' 
import ImageModal from '../modals/ImageModal'

export default function ImageZoon({ src = Map}) {

    const [open, setOpen] = useState(false);

    const handleClose=()=>setOpen(false);
    const handleOpen=()=>setOpen(true);

    return (
        <>
            <Box className='image-zoom'>
                <img src={src} className='w-100' />
                <Fab 
                size="small" 
                color="primary" 
                aria-label="zoom" 
                className='fab'
                onClick={handleOpen}
                >
                    <ZoomInIcon />
                </Fab>
            </Box>
            {open&&<ImageModal src={src} open={open} handleClose={handleClose}/>}
        </>
    )
}
