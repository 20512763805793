import React from 'react' 
import { Container, Grid } from '@material-ui/core'
import Video from '../../Video'
import { useTranslation } from 'gatsby-plugin-react-i18next';

export default function CabinDemoVideo() {
    const { t } = useTranslation();
    return (
        <div 
        data-sal="slide-right"
        data-sal-duration="2000"
        className='cabin-demo-video'>
            <Container>
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <h1>{t('Reserve Video Title 1')}</h1>
                        <h1>{t('Reserve Video Title 2')}</h1>
                        <br></br>
                        <Video idVideo={'xvM-kkBgrn4'}/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
