import React, { useContext, useEffect, useState } from 'react' 
import { useTranslation } from 'react-i18next';
import { Container, Grid, Button, MenuItem, Select, InputLabel, FormControl, makeStyles, CircularProgress, Checkbox, FormControlLabel } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'
import { getBases, getCabinsInfo } from './../../../data'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { dateToDBFormat, dbFormatToMoment, dbFormatToPicker, getDurationBetweenDates, getMomentNowDate, minutesToHoursAndMinutes } from '../../../utils/datetime'

import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; 
import { es, enUS } from "date-fns/locale";
import moment from 'moment';
import { DataContext } from '../../../context/DataContext';
import { getTimeAllowed } from '../../../utils/functions';
import { I18nextContext } from 'gatsby-plugin-react-i18next';
import { HOURS_IN_ADVANCE, MIN_HOURS_RESERVATION } from '../../../contants/globals';
import { CABIN_TYPES, getPathImageCabinType, getPathImageCabinTypeGallery } from '../../../contants/apis';


const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
        width: '100%',
    },
}));

Yup.addMethod(Yup.string, "minTime", function (minTime, errorMessage) {
    return this.test(`test-min-time`, errorMessage, function (value) {
        const { path, createError } = this;
        const dateNow = moment().startOf('day').format('yyyy-MM-DD');
        const minDateTime = moment(`${dateNow} ${this.resolve(minTime)}`, 'yyyy-MM-DD HH:mm');
        const dateTime = moment(`${dateNow} ${value}`, 'yyyy-MM-DD HH:mm');
        //   console.log(this.resolve(minTime),value,dateNow,minDateTime.format('yyyy-MM-DD HH:mm'),dateTime.format('yyyy-MM-DD HH:mm'));
        //   console.log(dateTime.isSameOrAfter(minDateTime));
        return (
            dateTime.isSameOrAfter(minDateTime) ||
            createError({ path, message: errorMessage })
        );
    });
});

export default function ReserveSearcher({ data }) {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation();
    const [bases, setBases] = useState([]);
    const { setTypeCabin, setBooking,setCabinTypeId} = useContext(DataContext);
    const { language } = useContext(I18nextContext);

    const formik = useFormik({
        initialValues: {
            base: data?.base || '',
            initDate: data?.initDate || moment().add(HOURS_IN_ADVANCE, 'hours').toDate(),
            initTime: data?.initTime || '',
            endDate: data?.endDate || moment().add(HOURS_IN_ADVANCE, 'hours').toDate(),
            endTime: data?.endTime || '',
            isDisabled: data?.isDisabled || false,
        },
        validationSchema: Yup.object({
            base: Yup
                .string()
                .required(`El Aeropuerto es requerido`),
            initDate: Yup
                .date("No es una fecha válida")
                .min(getMomentNowDate(0), 'No se puede seleccionar una fecha anterior a la de hoy')
                .max(Yup.ref("endDate"), 'No se puede seleccionar una fecha mayor al del check out')
                .required('La fecha de check in es requerido'),
            initTime: Yup
                .string()
                .required('La hora de check in es requerido'),
            endDate: Yup
                .date()
                .min(getMomentNowDate(0, Yup.ref("initDate")), 'No se puede seleccionar una fecha anterior a la del check in')
                .required('La fecha de check out es requerido'),
            endTime: Yup
                .string()
                // .minTime(Yup.ref("initTime"), 'No se puede seleccionar una hora menor a la de check in')
                .required('La hora de check out es requerido'),
            isDisabled: Yup
                .boolean()
        }),
        onSubmit: async (formData) => { 
            search(formData, bases);
        }

    })

    //console.log(formik.values.initDate,'date'); 
    const search = (formData, basesData) => {
        setBooking({});
        setTypeCabin({});
        const _data = { ...formData };
        _data.cabinTypeId=formData.isDisabled?CABIN_TYPES.SPECIAL:CABIN_TYPES.REGULAR;
        _data.initDate = dateToDBFormat(_data.initDate);
        _data.endDate = dateToDBFormat(_data.endDate);
        //_data.initDateTime = moment.utc(_data.initDate + " " + _data.initTime).format('yyyy-MM-DD HH:mm');
        _data.initDateTime = moment(_data.initDate + " " + _data.initTime).toISOString();
        //_data.endDateTime = moment.utc(_data.endDate + " " + _data.endTime).format('yyyy-MM-DD HH:mm'); 
        _data.endDateTime = moment(_data.endDate + " " + _data.endTime).toISOString(); 
        setLoading(true);
        getCabinsInfo(_data)
            .then(value => { 
                value.data_url = getPathImageCabinType(value.ct_id);
                const gallery = value.gallery.map((item) => {
                    const data_url = getPathImageCabinTypeGallery(item.cti_id);
                    item.data_url = data_url;
                    return item;
                });
                value.gallery = gallery;
                const baseSelected = basesData.find((item) => item.base_id === _data.base);
                const durationMin = getDurationBetweenDates(dbFormatToMoment(_data.endDateTime), dbFormatToMoment(_data.initDateTime));
                const duration = minutesToHoursAndMinutes(durationMin);
                const booking = {
                    book_total: value.price,
                    book_init_datetime: _data.initDateTime,
                    book_end_datetime: _data.endDateTime,
                    book_base_id: baseSelected.base_id,
                    base: baseSelected,
                    book_duration_hours: duration.hours,
                    book_duration_minutes: duration.minutes,
                    book_duration_sec: durationMin * 60
                };
                setBooking(booking);
                setTypeCabin(value);
                setCabinTypeId(_data.cabinTypeId);
            })
            .catch(error => {
                //alert('error');
                console.log(error.message);
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        getBases()
        .then(response => {
            setBases(response);
            //console.log(data);
            if (data) search(data, response);
        })
        .catch(console.log);
    }, [data])

    return (
        <div
            data-sal="slide-right"
            data-sal-duration="2000"
            className='reserve-searcher'>
            <Container>
                <form noValidate autoComplete="off" className='form' onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p>{t('Airport')}</p>
                                    </Grid>
                                </Grid>
                                <FormControl variant="outlined" className={classes.formControl}>
                                    <InputLabel
                                        id="demo-simple-select-outlined-label"

                                    >{t('Airport')}
                                    </InputLabel>
                                    <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        label={t('Airport')}
                                        onChange={(event, child) => formik.setFieldValue('base', event.target.value)}
                                        value={formik.values.base}
                                        error={formik.touched.base && formik.errors.base}
                                    >
                                        <MenuItem value="">
                                            <em>{t('None')}</em>
                                        </MenuItem>
                                        {
                                            bases.map((item, index) => (
                                                <MenuItem key={index} value={item.base_id}>{`${item.base_iata_code} ${item.base_name}`}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p>{t('Check in')}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === 'es' ? es : enUS}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                inputVariant={'outlined'}
                                                margin="normal"
                                                id="date-picker-inline"
                                                label=""
                                                fullWidth
                                                minDate={moment().add(HOURS_IN_ADVANCE, 'hours').toDate()}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                onChange={(name, value) => formik.setFieldValue('initDate', moment(value, 'DD/MM/yyyy').toDate())}
                                                value={formik.values.initDate}
                                                error={formik.touched.initDate && formik.errors.initDate}
                                                helperText={''}
                                            />  
                                            
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel
                                                id="select-init-datetime"

                                            >{t('Hora')}
                                            </InputLabel>
                                            <Select
                                                labelId="select-init-datetime"
                                                id="demo-simple-select-outlined"
                                                onChange={(event, child) => {
                                                    formik.setFieldValue('initTime', event.target.value);
                                                    if(getTimeAllowed(formik.values.endTime,HOURS_IN_ADVANCE).length==0){
                                                        formik.setFieldValue('endTime','');
                                                    }
                                                }}
                                                value={formik.values.initTime}
                                                error={formik.touched.initTime && formik.errors.initTime}
                                                label={t('Hora')}
                                                IconComponent={() => <AccessTime style={{ color: '#777777', marginRight: 16 }} />}
                                            >
                                                <MenuItem value="">
                                                    <em>{t('None')}</em>
                                                </MenuItem>
                                                {
                                                    getTimeAllowed(formik.values.initDate, HOURS_IN_ADVANCE).map((val, index) => (
                                                        <MenuItem key={index} value={val}>{val}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p>{t('Check out')}</p>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={language === 'es' ? es : enUS}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="dd/MM/yyyy"
                                                margin="normal"
                                                inputVariant={'outlined'}
                                                id="date-picker-inline-end"
                                                label=""
                                                fullWidth
                                                minDate={formik.values.initDate}
                                                maxDate={moment(formik.values.initDate).add(HOURS_IN_ADVANCE, 'hours').toDate()}
                                                onChange={(name, value) => formik.setFieldValue('endDate', moment(value, 'DD/MM/yyyy').toDate())}
                                                value={dbFormatToPicker(formik.values.endDate)}
                                                error={formik.touched.endDate && formik.errors.endDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                helperText={''}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container>
                                        <FormControl variant="outlined" className={classes.formControl}>
                                            <InputLabel
                                                id="select-end-datetime" >{t('Hora')}</InputLabel>
                                            <Select
                                                labelId="select-end-datetime"
                                                id="demo-simple-select-outlined"
                                                onChange={(event, child) => formik.setFieldValue('endTime', event.target.value)}
                                                value={formik.values.endTime}
                                                error={formik.touched.endTime && formik.errors.endTime}
                                                label={t('Hora')}
                                                IconComponent={() => <AccessTime style={{ color: '#777777', marginRight: 16 }} />}
                                            >
                                                <MenuItem value="">
                                                    <em>{t('None')}</em>
                                                </MenuItem>
                                                {
                                                    getTimeAllowed(formik.values.endDate, MIN_HOURS_RESERVATION,
                                                        formik.values.initDate, formik.values.initTime,true)
                                                        .map((value, index) => (
                                                            <MenuItem key={index} value={value}>{value}</MenuItem>
                                                        ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Button
                                    variant='contained'
                                    color="primary"
                                    size="large"
                                    fullWidth type='submit'
                                    disabled={loading}
                                >
                                    {!loading && t('Search')}
                                    {loading && <CircularProgress color='inherit' size={28} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <FormControl>
                            <FormControlLabel
                                control={
                                    <Checkbox 
                                        onChange={formik.handleChange}
                                        checked={formik.values.isDisabled}
                                        name="isDisabled"
                                        color="primary"
                                    />
                                }
                                label={t('Reserve disabled')}
                            />
                        </FormControl>
                    </Grid>
                </form>
            </Container>
        </div>
    )
}
