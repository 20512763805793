import { Grid } from '@material-ui/core'
import React from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'; 
// import './Message.scss';

export default function Message({message='',title=''}) {
    return (
        <Grid container spacing={2} className='reserve-message'>
            <Grid container item xs={12} justify={'center'}>
                <h1><ErrorOutlineIcon style={{ color: '#D5EFF5',fontSize: 60 }} /></h1>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <h2>{title}</h2>
            </Grid>
            <Grid container item xs={12} justify={'center'}>
                <p className='message'>{message}</p>
            </Grid>
        </Grid>
    )
}
