import React, { useContext, useMemo } from 'react'
// import './CabinImages.scss'
import ImageGallery from 'react-image-gallery';
// import regularCabin from '../../../../images/cabin-regular.png'
import { DataContext } from '../../../../context/DataContext';

export default function CabinImages() {
    const { typeCabin} = useContext(DataContext);

    // const images = [
    //     {
    //         original: 'https://picsum.photos/id/1018/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1018/250/150/',
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1015/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1015/250/150/',
    //     },
    //     {
    //         original: 'https://picsum.photos/id/1019/1000/600/',
    //         thumbnail: 'https://picsum.photos/id/1019/250/150/',
    //     },
    // ];
    // const images = [
    //     {
    //         original: regularCabin,
    //         thumbnail: regularCabin,
    //     },
    //     {
    //         original: regularCabin,
    //         thumbnail: regularCabin,
    //     },
    //     {
    //         original: regularCabin,
    //         thumbnail: regularCabin,
    //     },
    // ];

    const imagesUrl = useMemo(() =>{
        const data = [];
        const image = {original:typeCabin.data_url,thumbnail:typeCabin.data_url};
        data.push(image);
        for (const item of typeCabin.gallery) {
            data.push({original:item.data_url,thumbnail:item.data_url});
        }
        return data;
    }, [typeCabin]);


    return (
        <ImageGallery 
        items={imagesUrl}
        thumbnailPosition={'right'}
        showNav={false}
        showPlayButton={false}
        infinite
        />
    )
}
