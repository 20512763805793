import React from 'react'
// import './CabinTypeDetail.scss'
import { makeStyles } from '@material-ui/core/styles';
import { CardActions, CardContent, Button, Typography, Card, Grid } from '@material-ui/core';
import IconCabinDetail1 from '../../../../images/icon-cabin-detail-1.png';
import IconCabinDetail2 from '../../../../images/icon-cabin-detail-2.png';
import IconCabinDetail3 from '../../../../images/icon-cabin-detail-3.png';
import IconCabinDetail4 from '../../../../images/icon-cabin-detail-4.png';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        borderRadius: 12,
        boxShadow: '-1px -1px 12px 0px #a4a7ab9f'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
        fontStyle: 'normal'
    },
});

export default function CabinTypeDetail({ typeCabin }) {
    const classes = useStyles();
    const { t } = useTranslation();
    // const { language } = useContext(I18nextContext);
    console.log(typeCabin)
    return (
        <Card
            className={classes.root + ' cabin-type-detail'}
            variant="outlined"
        >
            <CardContent>
                <Typography variant="h5" component="h2" color='primary'>
                    { t(`Reserve ${typeCabin?.ct_id != 2 ? 'Regular' : 'Deluxe'} Cabin`)}
                </Typography>
                <p>
                    {t(`Reserve ${typeCabin?.ct_id != 2 ? 'Regular' : 'Deluxe'} Cabin Detail`)}
                </p>
                <br></br>
                <Grid container className='features' spacing={1}>
                    <Grid container item xs={12} md={6} alignItems={'center'}>
                        <img src={IconCabinDetail1} alt='detalle 1'/>
                        <span>{t(`Reserve ${typeCabin?.ct_id != 2 ? 'Regular' : 'Deluxe'} Cabin Detail C1`)}</span>
                    </Grid>
                    <Grid container item xs={12} md={6} alignItems={'center'}>
                        <img src={IconCabinDetail2} alt='detalle 2'/>
                        <span>{t(`Reserve Regular Cabin Detail C2`)}</span>
                    </Grid>
                    <Grid container item xs={12} md={6} alignItems={'center'}>
                        <img src={IconCabinDetail3} alt='detalle 3'/>
                        <span>{t('Reserve Regular Cabin Detail C3')}</span>
                    </Grid>
                    <Grid container item xs={12} md={6} alignItems={'center'}>
                        <img src={IconCabinDetail4} alt='detalle 4'/>
                        <span>{t('Reserve Regular Cabin Detail C4')}</span>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Grid container className='card-actions'>
                    <Grid container item xs={12} justify={'space-between'} alignItems={'center'}>
                        <div>
                            <span>Total</span>
                            <h2>{`US$${typeCabin?.price || 0}`}</h2>
                        </div>
                        <Link to='/check-out'>
                            <Button
                                variant='contained'
                                color="primary"
                            // onClick={()=> navigate(language==='en'?`/${language}/check-out`:`/check-out`)}
                            >
                                {t('Reserve')}
                            </Button>
                        </Link>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
}