import React, { useContext, useEffect } from 'react'
// import './SearcherResults.scss'
import CabinImages from './CabinImages'
import CabinTypeDetail from './CabinTypeDetail'
import { Container, Grid } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { DataContext } from '../../../context/DataContext' 
import Message from './Message' 

export default function SearcherResults() {
    const { t } = useTranslation();
    const { typeCabin, setTypeCabin } = useContext(DataContext);

    useEffect(() => {
        return () => {
            setTypeCabin({});
        }
    }, [setTypeCabin])

    const getStockString = (value) => value <= 1 ? `${value} ${t('Reserve Stock Singular')}` : `${value} ${t('Reserve Stock Plural')}`;

    return (
        <div className={typeCabin?.stock || typeCabin.stock === 0 ? 'searcher-results' : ''}>
            <Container>
                {typeCabin?.stock > 0 &&
                    (<><Grid container spacing={4}>
                        <Grid item xs={12}>
                            <h1>{t('Reserve Wait')}</h1>
                            <p>{getStockString(typeCabin.stock || 0)}</p>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <CabinImages />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <CabinTypeDetail typeCabin={typeCabin} />
                        </Grid> 
                    </Grid> 
                    </>)}
                {typeCabin?.stock === 0 &&
                    <Message title={t('Reserve Not found Cabins Title')} message={t('Reserve Not found Cabins Message')} />
                }
            </Container>
        </div>
    )
}
