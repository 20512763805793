import React from 'react'
import { Container, Grid } from '@material-ui/core'
import PinDropIcon from '@material-ui/icons/PinDrop';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import ImageZoom from '../../ImageZoom'

export default function FindUs() {
    const { t } = useTranslation();
    return (
        <div
            className='find-us'>
            <Container
                data-sal="slide-left"
                data-sal-duration="2000">
                <Grid container spacing={4} alignItems='center'>
                    <Grid item md={3}>
                        <div><h1><PinDropIcon />{t('Reserve Find Us Title')}</h1></div>
                        <p>{t('Reserve Find Us Message')}</p>
                    </Grid>
                    <Grid item md={4}>
                        <ImageZoom />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
