import React from 'react'
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import { graphql } from 'gatsby';
import Footer from './../components/layouts/Footer'
import ReserveSearcher from './../components/reserve/ReserveSearcher'
import {Divider} from '@material-ui/core'
import SearcherResults from './../components/reserve/SearcherResults' 
import Protocols from './../components/reserve/Protocols' 
import NeedHelp from './../components/reserve/NeedHelp' 
import FindUs from './../components/reserve/FindUs'
import CabinDemoVideo from './../components/reserve/CabinDemoVideo' 
import Faqs from './../components/reserve/Faqs' 

export default function Reserve({ location }) {
    //search
    const data = location.state?.data || '';
    return (
        <MainLayout withMargin>
            <Seo title="Reserve" />
            <ReserveSearcher data={data}/>
            <Divider />
            <SearcherResults />
            <Protocols/>
            <NeedHelp/>
            <FindUs/>
            <CabinDemoVideo/>
            <Faqs/>
            <Footer/>
        </MainLayout>
    )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
