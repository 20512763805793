import React, { useEffect, useState } from 'react'
import { Container, Accordion, AccordionDetails, AccordionSummary, Grid, makeStyles, Typography } from '@material-ui/core'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import ExpandAddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { getGlobalConfigurations } from '../../../data';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function Faqs() {

    const [expanded, setExpanded] = useState(false);
    const [selectIndex, setSelectIndex] = useState(null);
    const [configurations, setConfigurations] = useState({});
    const classes = useStyles();
    const { t } = useTranslation();

    useEffect(() => {
        getGlobalConfigurations()
            .then(response => {
                setConfigurations(response);
            })
            .catch(console.log);
    }, [])

    const penaltyOneAndMore = "100 USD";
    const price = "11.49 USD";
    const preathorizedAmount = "80 USD";

    const faq = [
        { title: t('Faq Title 1'), detail: t('Faq Detail 1') },
        { title: t('Faq Title 2'), detail: `${t('Faq Detail 2 p1')} ${configurations?.penaltyIncomeMultiplePeople || 'X'} ${t('Faq Detail 2 p2')}` },
        { title: t('Faq Title 3'), detail: `${t('Faq Detail 3 p1')} ${configurations?.cabinPrice || 'X'} ${t('Faq Detail 3 p2')}` },
        { title: t('Faq Title 4'), detail: `${t('Faq Detail 4 p1')}` },
        { title: t('Faq Title 5'), detail: t('Faq Detail 5') },
        { title: t('Faq Title 6'), detail: t('Faq Detail 6') },
        { title: t('Faq Title 7'), detail: t('Faq Detail 7') },
        { title: t('Faq Title 8'), detail: t('Faq Detail 8') },
        { title: t('Faq Title 9'), detail: t('Faq Detail 9') },
        { title: t('Faq Title 10'), detail: t('Faq Detail 10') },
        { title: t('Faq Title 11'), detail: `${t('Faq Detail 11 p1')} ${configurations?.cabinPrice || 'X'} ${t('Faq Detail 11 p2')}` },
    ];

    return (
        <div
            className='faqs'>
            <Container
                data-sal="slide-left"
                data-sal-duration="2000">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <h1>FAQ’s</h1>
                        <br></br>
                        <div className={classes.root}>
                            {faq.map((item, index) => (
                                <Accordion key={index} onChange={(e, expanded) => {
                                    setExpanded(expanded);
                                    setSelectIndex(index);
                                }}>
                                    <AccordionSummary
                                        expandIcon={(expanded && selectIndex === index) ? <RemoveIcon /> : <ExpandAddIcon />}
                                        aria-controls={`faq-${index}`}
                                        id={`faq-${index}`}
                                    >
                                        <Typography color={(expanded && selectIndex === index) ? 'primary' : 'initial'} className={classes.heading}>{item.title}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Typography><Trans>{item.detail}</Trans></Typography>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
